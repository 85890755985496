html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

#__next {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
